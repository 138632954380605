import { ref } from "vue";
import { projectFirestore } from "../firebase/config";
import configSetup from "../setup";

const { companyProfileName } = configSetup();

const useWarrantyCollection = () => {
  const error = ref(null);
  const isPending = ref(false);

  // add a new document
  const addDoc = async (doc, doc2) => {
    error.value = null;
    isPending.value = true;

    try {
      const warrantyRef = await projectFirestore
        .collection("companyProfile")
        .doc(companyProfileName)
        .collection("tintWarranties")
        .doc();

      await warrantyRef.set(doc);
      projectFirestore
        .collection("companyProfile")
        .doc(companyProfileName)
        .collection("tintWarranties")
        .doc(warrantyRef.id)
        .set({ warrantyRef: warrantyRef.id }, { merge: true });
      const clientRef = await projectFirestore
        .collection("companyProfile")
        .doc(companyProfileName)
        .collection("tintWarranties")
        .doc(warrantyRef.id)
        .collection("client")
        .doc("clientInfo");
      await clientRef.set(doc2);
      isPending.value = false;
    } catch (err) {
      console.log(err.message);
      error.value = "could not add the warranty certificate";
      isPending.value = false;
    }
  };

  return { error, addDoc, isPending };
};

export default useWarrantyCollection;
