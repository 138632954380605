<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" static class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false" :open="sidebarOpen">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-900">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? `${bgbrand800} text-white` : `${textbrand100} hover:${bgbrand600}`, 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                  <component :is="item.icon" class="mr-4 flex-shrink-0 h-6 w-6" :class="`${textbrand300}`" aria-hidden="true" />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-gray-900 md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component -->
        <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div class="mt-5 flex-1 flex flex-col">
            <nav class="flex-1 px-2 space-y-1">
              <router-link :to="{ name: item.href }" v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? `${bgbrand800} text-white` : `${textbrand100} hover:${bgbrand600}`, 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                <component :is="item.icon" class="mr-3 flex-shrink-0 h-6 w-6" :class="`${textbrand300}`" aria-hidden="true" />
                {{ item.name }}
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset md:hidden" :class="`focus:${ringbrand500}`" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between">

          <div class="ml-4 flex items-center md:ml-6">
             <h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
          </div>
        </div>
      </div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
           
          </div>
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <!-- Start Dashboard Content -->
            <div class="py-10">
                <main>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <!-- Form Starts -->
            <form @submit.prevent="handleSubmit" class="space-y-8 divide-y divide-gray-200">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                <div>
                    <div>
                    <h3 class="text-2xl leading-6 font-medium" :class="`${textbrand500}`">
                        Warranty Creator
                    </h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">
                        Create client warranty certificates
                    </p>
                    </div>

                    <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900 border-t pt-5">
                        Client Information
                    </h3>
                    </div>  
                        
<!-- Client Name  -->

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="client-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Client name
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input required type="text" autocomplete="client-name" v-model="clientName" class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" :class="`focus:${ringbrand500} focus:${borderbrand500}`"/>
                            </div>
                        </div>
                    </div>
                                                
<!-- Client Phone Number  -->

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="clientPhone" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Client Phone Number
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input required type="text" autocomplete="clientPhone" v-model="clientPhone" maxlength="12" minlength="12" class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" :class="`focus:${ringbrand500} focus:${borderbrand500}`"/>
                            </div>
                        </div>
                    </div>
                                                      
<!-- Client Email -->

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="clientEmail" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Client Email
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input required type="email" autocomplete="clientEmail" v-model="clientEmail" class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" :class="`focus:${ringbrand500} focus:${borderbrand500}`"/>
                            </div>
                        </div>
                    </div>
                    
<!-- Warranty Validity  -->
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="warranty" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Warranty Validity
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select required v-model="warranty" id="warranty" name="warranty" autocomplete="warranty" class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                <option>3 Years</option>
                                <option>Lifetime</option>
                                  
                            </select>
                            </div>
                        </div>

                        
<!-- Date Tinted  -->

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="client-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Date Tinted
                        </label>
                        <div class="flex mt-1 sm:mt-0 sm:col-span-2 space-x-2">
                            <select required v-model="dateMonth" id="dateMonth" name="dateMonth" autocomplete="dateMonth" class="max-w-md block shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                <option>January</option>
                                <option>February</option>
                                <option>March</option>
                                <option>April</option>
                                <option>May</option>
                                <option>June</option>
                                <option>July</option>
                                <option>August</option>
                                <option>September</option>
                                <option>October</option>
                                <option>November</option>
                                <option>December</option>
                                  
                            </select>
                            <select required v-model="dateDay" id="dateDay" name="dateDay" autocomplete="dateDay" class="max-w-md block shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                                <option>19</option>
                                <option>20</option>
                                <option>21</option>
                                <option>22</option>
                                <option>23</option>
                                <option>24</option>
                                <option>25</option>
                                <option>26</option>
                                <option>27</option>
                                <option>28</option>
                                <option>29</option>
                                <option>30</option>
                                <option>31</option>
                                  
                            </select>
                            <select required v-model="dateYear" id="dateYear" name="dateYear" autocomplete="dateYear" class="max-w-md block shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                <option>2020</option>
                                <option>2021</option>
                                <option>2022</option>
                            </select>
                        </div>
                    </div>

<!-- Tint Brand  -->
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="tintBrand" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Tint Brand
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <select required v-model="tintBrand" id="tintBrand" name="tintBrand" autocomplete="tintBrand" class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                            <option>Avery Dennison</option>
                            <option>Global Window Films</option>
                        </select>
                        </div>
                    </div>
                                                      
<!-- Pruduct Number -->

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="clientEmail" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Product Number (optional)
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" v-model="productNumber" class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" :class="`focus:${ringbrand500} focus:${borderbrand500}`"/>
                            </div>
                        </div>
                        </div>

                        <div>
                          <h3 class="text-lg leading-6 font-medium text-gray-900 border-t pt-5">
                              Vehicle Information
                          </h3>
                        </div>  

<!-- Vehicle Name  -->

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="vehicle-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Vehicle name
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input required type="text" autocomplete="vehicle-name" v-model="vehicleName" class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" :class="`focus:${ringbrand500} focus:${borderbrand500}`"/>
                            </div>
                        </div>
                    </div>

<!-- Vin number  -->

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="vinNumber" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Vin number
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input required type="text" autocomplete="vinNumber" v-model="vinNumber" class="flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" :class="`focus:${ringbrand500} focus:${borderbrand500}`"/>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
                
<!-- Install information  -->

                <div class="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Install Information
                    </h3>
                    </div>

                    <div class="space-y-6 sm:space-y-5">

<!-- Vehicle Type  -->
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="Vehicle-type" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Vehicle Type
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select required v-model="VehicleType" id="Vehicle-type" name="Vehicle-type" autocomplete="Vehicle-type" class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                <option>3DR Hatchback</option>
                                <option>Compact SUV</option>
                                <option>Coupe</option>
                                <option>SUV</option>
                                <option>Sedan</option>
                                <option>5DR Hatchback</option>
                                <option>2DR Truck</option>
                                <option>Station Wagon</option>
                                <option>Van</option>
                                <option>4DR Truck</option>
                                  
                            </select>
                            </div>
                        </div>

<!-- Install Type  -->

                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="install-type" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Install Type
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select required v-model="installType" id="install-type" name="install-type" autocomplete="install-type" class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                <option>Rear Install</option>
                                <option>Full Install</option>
                                <option>Front Windows</option>
                            </select>
                            </div>
                        </div>

<!-- Shade Option Rear  -->

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label for="shadeOptionRear" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Shade Option REAR
                              </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <select required v-model="shadeOptionRear" id="shadeOption" name="shadeOption" class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                    <option>None</option>
                                    <option>5%</option>
                                    <option>20%</option>
                                    <option>30%</option>
                                </select>
                              </div>
                            </div>

<!-- Shade Option Front  -->

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Shade Option FRONT
                              </label>
                              <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <select required v-model="shadeOptionFront" autocomplete="shadeOptionFront" class="max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md" :class="`focus:${ringbrand500} focus:${borderbrand500}`">
                                  <option>None</option>
                                  <option>5%</option>
                                  <option>20%</option>
                                  <option>30%</option>
                                </select>
                              </div>
                            </div>  
                          </div>
                        </div>
                      </div>
                    <div class="pt-5">

<!-- Buttons -->

                      <div class="flex justify-end">
                        <button v-if="!isPending" type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`${bgbrand600} hover:${bgbrand700} focus:${ringbrand500}`">
                          Create
                        </button>
                        <button v-else disabled type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2" :class="`hover:${bgbrand700} focus:${ringbrand500}`">
                          Creating...
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </main>
            </div>
            <!-- /End Dashboard -->
          </div>
        </div>
      </main>
    </div>
  </div>
  
</template>

<script>
import { ref } from 'vue'
import useStorage from '@/composables/useStorage.js'
import createWarranty from '@/composables/createWarranty.js'
import { timestamp } from '@/firebase/config'
import configSetup from '@/setup.js'

import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'

const settingsIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>

const galleryIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const warrantyIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
</svg>

const galleryListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
</svg>

const warrantyListIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
</svg>

const pricesIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
</svg>

const calendarIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>

const navigation = [
  // { name: 'Settings', href: 'Settings', icon: settingsIcon, current: false },
  { name: 'Booking Request', href: 'BookingRequests', icon: calendarIcon, current: false },
  { name: 'Gallery Upload', href: 'ImageLoader', icon: galleryIcon, current: false },
  { name: 'Gallery Edit', href: 'GalleryEdit', icon: galleryListIcon, current: false },
  { name: 'Warranty Creator', href: 'WarrantyCreator', icon: warrantyIcon, current: true },
  { name: 'Warranty Search', href: 'WarrantyList', icon: warrantyListIcon, current: false },
  { name: 'Service Prices', href: 'ServicePrices', icon: pricesIcon, current: false },

]

export default {
    created() {
        const {company} = configSetup()
        document.title = `${company} | Warranty Creator`
    },

    components: {
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        BellIcon,
        MenuAlt2Icon,
        SearchIcon,
        XIcon,
    },

    setup(){
        const sidebarOpen = ref(false)
        const {
          textbrand100,
          textbrand300,
          textbrand500,
          borderbrand500,
          bgbrand600,
          bgbrand700,
          bgbrand800,
          ringbrand500,
          dbWarranty
        } = configSetup()

        const {url, filePath, uploadImage, uploadWarrantyImage} = useStorage()
        const {error, addDoc} = createWarranty()


        
        const clientName = ref('')
        const clientPhone = ref('')
        const clientEmail = ref('')
        const warranty  = ref('')
        const dateMonth = ref('')
        const dateDay = ref('')
        const dateYear = ref('')
        const tintBrand = ref('')
        const vinNumber = ref('')
        const vehicleName = ref('')
        const VehicleType = ref('')
        const installType = ref('')
        const shadeOptionFront = ref('')
        const shadeOptionRear = ref('')
        const productNumber = ref('')
        const isPending = ref(false)

        // Allowed types
        const types = ['image/png', 'image/jpeg']


        const handleSubmit = async () => {
            
                isPending.value = true
                
                await addDoc({
                    
                    warranty: warranty.value,
                    date: `${dateMonth.value} ${dateDay.value} ${dateYear.value}`,
                    tintBrand: tintBrand.value,
                    vinNumber: vinNumber.value,
                    vehicleName: vehicleName.value,
                    vehicleType: VehicleType.value,
                    installType: installType.value,
                    shadeOptionFront: shadeOptionFront.value,
                    shadeOptionRear: shadeOptionRear.value,
                    productNumber: productNumber.value,
                    createdAt: timestamp(),
                    emailSent: false,                                                 
                }, {
                    clientName: clientName.value,
                    clientPhone: clientPhone.value,
                    clientEmail: clientEmail.value,
                })
                isPending.value = false

                if (!error.value){
                    clientName.value = ''
                    clientPhone.value = ''
                    clientEmail.value = ''
                    warranty.value = ''
                    dateMonth.value = ''
                    dateDay.value = ''
                    dateYear.value = ''
                    vinNumber.value = ''
                    vehicleName.value = ''
                    VehicleType.value = ''
                    installType.value = ''
                    shadeOptionRear.value = ''
                    shadeOptionFront.value = ''
                }

            
            
        }

    return {
        clientName,
        clientPhone,
        clientEmail,
        warranty,
        dateMonth,
        dateDay,
        dateYear,
        tintBrand,
        vinNumber,
        vehicleName,  
        VehicleType, 
        installType, 
        shadeOptionFront,
        shadeOptionRear, 
        handleSubmit,
        isPending,
        navigation,
        sidebarOpen,
        textbrand100,
        textbrand300,
        textbrand500,
        borderbrand500,
        bgbrand600,
        bgbrand700,
        bgbrand800,
        ringbrand500,
        dbWarranty,
        productNumber,
        }
    }

}
</script>